import React from 'react';
import logo from '../img/logo-white.png';

const Navbar = () => {

  return (
    <header className="w-full fixed bg-purple-400 shadow-md py-4 z-10">
      <div className="flex items-center justify-center px-6">
        <a href="/"><img src={logo} alt="Logo" className='h-12' /></a>
      </div>
    </header>
  );
};

export default Navbar;
