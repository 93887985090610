import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';
import Loading from './Loading';

const RegisterForm = ( { setShowOtp, email, setEmail } ) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send the data to the API
      const response = await axios.post('https://api.ekaddigitalkahwin.com/api/resellers/register', {
        name,
        email,
      });      

      // Handle success
      setShowOtp(true);
      // console.log('Registration successful! Please check your email for OTP.');
    } catch (error) {
      if (error.response) {
        // Server-side error, log the details
        // console.log('Registration failed:', error.response.data);
        setLoading(false);
        setMessage(error.response.data.message);
      } else {
        // Client-side or network error
        // console.log('Error occurred:', error.message);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Navbar />
      {loading ? (
        <Loading />
      ):(
        <div className="min-h-screen flex md:items-center justify-center pt-20 bg-white md:bg-gray-100">
          <div className="bg-white p-6 rounded md:shadow-md w-full max-w-md ">
            <h2 className="text-2xl font-bold mb-6 text-center">Daftar Reseller</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700">Nama Penuh</label>
                <input
                  type="text"
                  id="name"
                  value={name}
                  placeholder="Cth: Muhammad Adam bin Ismail"
                  onChange={(e) => setName(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  placeholder="Cth: adam@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <p className='my-4 text-purple-400'>{message}</p>
              
              <button
                type="submit"
                className="w-full bg-purple-400 text-white py-2 rounded-lg hover:bg-purple-500 transition"
              >
                Register
              </button>
            </form>
            <p className="mt-4 text-center">
              Already have an account? <Link to="/login" className="text-purple-400">Login</Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegisterForm;
