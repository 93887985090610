import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import Statistics from './Statistics';
import PromoCodeList from './PromoCodeList';

const DashboardComponents = ({ setLoading }) => {
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('userEmail'));
  const [showPromo, setShowPromo] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [nextDay, setNextDay] = useState('');

  useEffect(() => {
    if(email) {
      const response = axios.post('https://api.ekaddigitalkahwin.com/api/resellers/show', {
        email: email,
      })
        .then(response => {
          setName(response.data.reseller.name);
          setUserId(response.data.reseller.user_id);
          setEmail(response.data.reseller.email);
          setLoading(false);
          // console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      console.log("No data");
    }
  }, [setName, setEmail, setLoading]);

  useEffect(() => {
    // Get current date in Malaysia's time zone
    const today = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kuala_Lumpur' }));

    // Calculate the next day
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Format the date as 'dd month year' in Malay (Malaysia)
    const options = { day: '2-digit', month: 'long', year: 'numeric' };
    const formattedDate = tomorrow.toLocaleDateString('ms-MY', options); // 'ms-MY' for Malay-Malaysia locale

    // Set the formatted date to state
    setNextDay(formattedDate);
  }, []);

  const handleGeneratePromoCode = async () => {
    setLoading(true);
    try {
      const response = await axios.post('https://api.ekaddigitalkahwin.com/api/promo-codes/generate', {
          email,
      });
      setPromoCode(response.data.promo_code);
      setShowPromo(true);
      setLoading(false);
    } catch (error) {
      console.error('Error generating promo code:', error);
      alert('Failed to generate promo code.');
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(promoCode).then(() => {
      alert('Promo code copied to clipboard!');
    }).catch((error) => {
      console.error('Failed to copy promo code:', error);
    });
  };

  const logOut = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('token');
    window.location.reload();
  };

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-GB');

  const embedUrl = `https://www.youtube.com/embed/sNYd565u7Zc?rel=0&loop=1&playlist=sNYd565u7Zc`;
  
  return (
    <div className="bg-gray-50 flex pt-20 justify-center min-h-screen">
      <div className=" px-8 py-8 max-w-md w-full space-y-4">
        <div className='flex items-center justify-between text-sm font-bold'>
          <div>
            <h1>Reseller Sah</h1>
            <h1>ID: RES{userId}</h1>
          </div>
          <p>{formattedDate}</p>
        </div>
        <div className='flex items-center justify-between'>
          <h1 className='text-lg'>Hi, {name}</h1>
          <button className='text-purple-400 hover:text-purple-500' onClick={logOut}>Log out?</button>
        </div>
        <button onClick={handleGeneratePromoCode} className='bg-purple-400 hover:bg-purple-500 w-full text-white text-center py-2 rounded-lg'>
          Generate Promo Code
        </button>
        {showPromo && (
          <div>
            <div className='flex items-stretch justify-center'>
              <input type='text' className='border rounded-lg py-2 px-3 w-full uppercase' value={promoCode}></input>
              <button onClick={copyToClipboard} className=' rounded-lg py-2 px-3 bg-green-400 hover:bg-green-500 text-white'><FaCopy /></button>
            </div>
            <div className='mt-4'>
              <p>Promo Code ini sah sehingga <span className='font-bold'>{nextDay}</span></p>
              <p>Guna Promo Code ini untuk dapat diskaun <span className='font-bold'>RM40</span> sebagai reseller.</p>
            </div>
          </div>
        )}
        <div className="video-container mb-8">
          {/* <iframe
            width="100%"
            height="500"
            src={embedUrl}
            title="Cara Copy Koordinat"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
        </div>
        <PromoCodeList email={email} />
        {/* <Statistics /> */}
      </div>
    </div>
  );
};

export default DashboardComponents;
