import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';
import Loading from './Loading';

const LoginForm = ( { setShowOtp, email, setEmail } ) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Send the data to the API
      const response = await axios.post('https://api.ekaddigitalkahwin.com/api/resellers/login', {
        email,
      });

      // Handle success
      setShowOtp(true);
    } catch (error) {
      if (error.response) {
        // Server-side error, log the details
        console.log('Login failed:', error.response.data);
        setMessage(error.response.data.message);
      } else {
        // Client-side or network error
        console.log('Error occurred:', error.message);
      }
    }
  };

  return (
    <div>
      <Navbar />
      {loading ? (
        <Loading />
      ):(
        <div className="min-h-screen flex md:items-center justify-center pt-20 bg-white md:bg-gray-100">
          <div className="bg-white p-6 rounded md:shadow-md w-full max-w-md ">
            <h2 className="text-2xl font-bold mb-6 text-center">Log Masuk</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700">Email</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Cth: adam@gmail.com"
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
              <p className='my-4 text-purple-400'>{message}</p>
              <button
                type="submit"
                className="w-full bg-purple-400 text-white py-2 rounded-lg hover:bg-purple-500 transition"
              >
                Login
              </button>
            </form>
            <p className="mt-4 text-center">
              Don't have an account? <Link to="/register" className="text-purple-400">Register</Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginForm;
